/** @jsx jsx */
import { jsx, Heading, Text, Box } from "theme-ui"

import Layout from "../components/Layout"

const PrivacyPage = () => {
  return (
    <Layout>
      <Box bg="#fff">
        <Box
          mx="auto"
          py={[60, 60, 100]}
          px={[20, 20, 40]}
          sx={{ maxWidth: 650 }}
        >
          <Heading sx={{ mb: 4 }}>Privacy policy</Heading>

          <Text variant="paragraph">
            It is Adam Monster&apos;s policy to respect your privacy regarding
            any information we may collect from you across our website.
          </Text>

          <Text variant="paragraph">
            We only ask for personal information when we truly need it to
            provide a service to you. We collect it by fair and lawful means,
            with your knowledge and consent. We also let you know why we’re
            collecting it and how it will be used.
          </Text>

          <Text variant="paragraph">
            We only retain collected information for as long as necessary to
            provide you with your requested service. What data we store, we’ll
            protect within commercially acceptable means to prevent loss and
            theft, as well as unauthorised access, disclosure, copying, use or
            modification.
          </Text>

          <Text variant="paragraph">
            We don’t share any personally identifying information publicly or
            with third-parties, except when required to by law.
          </Text>

          <Text variant="paragraph">
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            practices of these sites, and cannot accept responsibility or
            liability for their respective privacy policies.
          </Text>

          <Text variant="paragraph">
            You are free to refuse our request for your personal information,
            with the understanding that we may be unable to provide you with
            some of your desired services.
          </Text>

          <Text variant="paragraph">
            Your continued use of our website will be regarded as an acceptance
            of our practices around privacy and personal information. If you
            have any questions about how we handle user data and personal
            information, feel free to contact us.
          </Text>

          <Text variant="paragraph">
            This policy is effective as of 29 June 2019.
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default PrivacyPage
